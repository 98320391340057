export default [
    {
        path: "/ecommerce/order/list",
        name: "ecommerce-order-list",
        component: () => import("@/views/ecommerce/order/order-list/OrderList.vue"),
        meta: {
            resource: "ECOM",
            action: "read",
        },
    },
    {
        path: "/ecommerce/order/add",
        name: "ecommerce-order-add",
        component: () => import("@/views/ecommerce/order/order-add/OrderAdd.vue"),
        meta: {
            resource: "ECOM",
            action: "read",
        },
    },
    {
        path: "/ecommerce/order/:id",
        name: "ecommerce-order-preview",
        component: () => import("@/views/ecommerce/order/order-preview/OrderPreview.vue"),
        meta: {
            resource: "ECOM",
            action: "read",
        },
    },
    {
        path: "/ecommerce/order/edit/:id",
        name: "ecommerce-order-edit",
        component: () => import("@/views/ecommerce/order/order-edit/OrderEdit.vue"),
        meta: {
            resource: "ECOM",
            action: "read",
        },
    },
    {
        path: "/ecommerce/customer/list",
        name: "ecommerce-customer-list",
        component: () => import("@/views/ecommerce/customer/customer-list/CustomerList.vue"),
        meta: {
            resource: "ECOM",
            action: "read",
        },
    },
    {
        path: "/ecommerce/my-orders/list",
        name: "ecommerce-my-orders-list",
        component: () => import("@/views/ecommerce/my-orders/MyOrdersList.vue"),
        meta: {
            resource: "ECOM",
            action: "read",
        },
    },
    {
        path: "/ecommerce/tickets",
        name: "ecommerce-tickets",
        component: () => import("@/views/ecommerce/tickets/Tickets.vue"),
        meta: {
            resource: "ECOM",
            action: "read",
            contentRenderer: "sidebar-left",
            contentClass: "todo-application",
        },
    },
    {
        path: "/ecommerce/tickets/:filter",
        name: "ecommerce-tickets-filter",
        component: () => import("@/views/ecommerce/tickets/Tickets.vue"),
        meta: {
            contentRenderer: "sidebar-left",
            contentClass: "todo-application",
            navActiveLink: "ecommerce-tickets",
        },
        beforeEnter(to, _, next) {
            if (["important", "deleted", "my"].includes(to.params.filter)) next();
            else next({ name: "error-404" });
        },
    },
    {
        path: "/ecommerce/tickets/:tag",
        name: "ecommerce-tickets-tag",
        component: () => import("@/views/ecommerce/tickets/Tickets.vue"),
        meta: {
            contentRenderer: "sidebar-left",
            contentClass: "todo-application",
            navActiveLink: "ecommerce-tickets",
        },
        beforeEnter(to, _, next) {
            if (localStorage.getItem("ticketTags").includes(to.params.tag)) next();
            else next({ name: "error-404" });
        },
    },
    {
        path: "/ecommerce/customer/:id",
        name: "ecommerce-customer-view",
        component: () => import("@/views/ecommerce/customer/customer-view/CustomerView.vue"),
        meta: {
            resource: "ECOM",
            action: "read",
        },
    },
];
