export default [
    {
        path: "/marketing/channel/list",
        name: "marketing-channel-list",
        component: () => import("@/views/marketing/channel/channel-list/ChannelList.vue"),
        meta: {
            resource: "YT",
            action: "read",
        },
    },
    {
        path: "/marketing/channel/:id",
        name: "marketing-channel-view",
        component: () => import("@/views/marketing/channel/channel-view/ChannelView.vue"),
        meta: {
            resource: "YT",
            action: "read",
        },
    },
    {
        path: "/marketing/sms",
        name: "marketing-sms",
        component: () => import("@/views/marketing/sms/Chat.vue"),
        meta: {
            resource: "SMS",
            action: "read",
            contentRenderer: "sidebar-left",
            contentClass: "chat-application",
        },
    },
    // {
    //     path: "/marketing/sms/:id",
    //     name: "marketing-sms-view",
    //     component: () => import("@/views/marketing/sms/sms-view/SmsView.vue"),
    //     meta: {
    //         resource: "SMS",
    //         action: "read",
    //     },
    // }
    {
        path: "/marketing/shortened/list",
        name: "marketing-shortened-list",
        component: () => import("@/views/marketing/shortened/shortened-list/ShortenedList.vue"),
        meta: {
            resource: "YT",
            action: "read",
        },
    },
    {
        path: "/marketing/shortened/:id",
        name: "marketing-shortened-view",
        component: () => import("@/views/marketing/shortened/shortened-view/ShortenedView.vue"),
        meta: {
            resource: "YT",
            action: "read",
        },
    },
    // {
    //     path: "/ecommerce/order/add/",
    //     name: "ecommerce-order-add",
    //     component: () => import("@/views/ecommerce/order/order-add/OrderAdd.vue"),
    // },
    // {
    //     path: "/ecommerce/order/edit/:id",
    //     name: "ecommerce-order-edit",
    //     component: () => import("@/views/ecommerce/order/order-edit/OrderEdit.vue"),
    // },
];
