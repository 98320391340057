import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import moment from "moment";

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

Vue.filter("formatDate", value => {
    const date = moment(String(value));

    if (moment().startOf("day").diff(date, "seconds") < 86400) {
        return date.fromNow();
    }

    return date.format("MMMM DD, YYYY");
});

Vue.filter("formatDateTime", value => moment(String(value)).format("DD MMMM YYYY - hh:mm"));

Vue.filter("formatCurrency", value => {
    if (value) {
        return parseFloat(value).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "USD",
        });
    }

    return parseFloat(value).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "USD",
    });
});

Vue.directive("format-currency", {
    inserted: function (el, binding) {
        el.innerHTML = parseFloat(binding.value).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "USD",
        });
    },
});

Vue.filter("formatShipment", value => {
    if (value) {
        return `${value.replace(/-/g, " ")}`;
    }

    return false;
});

// Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount("#app");
