import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";
import dashboard from "./routes/dashboard";
import ecommerce from "./routes/ecommerce";
import marketing from "./routes/marketing";
import pages from "./routes/pages";
import admin from "./routes/admin";

// Store
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        };
    },
    routes: [
        {
            path: "/",
            redirect: {
                name: "dashboard-ecommerce",
            },
        },
        ...dashboard,
        ...ecommerce,
        ...marketing,
        ...pages,
        ...admin,
        {
            path: "*",
            redirect: "error-404",
        },
    ],
});

router.beforeEach((to, _, next) => {
    // Set unread sms and on hold order count from store
    store.dispatch("appAnalytics/getOnHoldOrderCount");
    store.dispatch("appAnalytics/getUnreadSmsCount");

    const isLoggedIn = isUserLoggedIn();

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) {
            return next({
                name: "auth-login",
            });
        }

        // If logged in => not authorized
        return next({
            name: "misc-not-authorized",
        });
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData();
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
    }

    return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");

    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;
