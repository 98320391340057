import axios from "@axios";

export default {
    namespaced: true,
    state: {
        onHoldOrderCount: "Loading",
        unreadSmsCount: "Loading",
    },
    getters: {
        onHoldOrderCount: state => state.onHoldOrderCount,
        unreadSmsCount: state => state.unreadSmsCount,
    },
    mutations: {
        SET_ON_HOLD_ORDER_COUNT(state, val) {
            state.onHoldOrderCount = val;
        },
        SET_UNREAD_SMS_COUNT(state, val) {
            state.unreadSmsCount = val;
        }
    },
    actions: {
        async getOnHoldOrderCount({ commit }) {
            const { data } = await axios.get("/analytics/application");
            localStorage.setItem("onHoldOrderCount", data.onHoldOrderCount);
            commit("SET_ON_HOLD_ORDER_COUNT", data.onHoldOrderCount);
        },
        async getUnreadSmsCount({ commit }) {
            const { data } = await axios.get("/analytics/application");
            localStorage.setItem("unreadSmsCount", data.unreadSmsCount);
            commit("SET_UNREAD_SMS_COUNT", data.unreadSmsCount);
        }
    }
};