export default [
    {
        path: "/admin/shop/list",
        name: "admin-shop-list",
        component: () => import("@/views/admin/shop/shop-list/ShopList.vue"),
        meta: {
            resource: "ADMIN",
            action: "read",
        },
    },
    {
        path: "/admin/shop/:id",
        name: "admin-shop-edit",
        component: () => import("@/views/admin/shop/shop-edit/ShopEdit.vue"),
        meta: {
            resource: "ADMIN",
            action: "read",
        },
    },
    {
        path: "/admin/user/list",
        name: "admin-user-list",
        component: () => import("@/views/admin/user/user-list/UserList.vue"),
        meta: {
            resource: "ADMIN",
            action: "read",
        },
    },
    {
        path: "/admin/user/:id",
        name: "admin-user-edit",
        component: () => import("@/views/admin/user/user-edit/UserEdit.vue"),
        meta: {
            resource: "ADMIN",
            action: "read",
        },
    },
    {
        path: "/admin/ticket-status",
        name: "admin-ticket-status",
        component: () => import("@/views/admin/ticket-status/TicketStatusList.vue"),
        meta: {
            resource: "ADMIN",
            action: "read",
        },
    },
    {
        path: "/admin/order-source",
        name: "admin-order-source",
        component: () => import("@/views/admin/order-source/OrderSourceList.vue"),
        meta: {
            resource: "ADMIN",
            action: "read",
        },
    },
];
